import React from "react";
import PropTypes from "prop-types";

import { ProfileStyle, ProfileImg } from "./style";

const Profile = ({ user }) => (
  <ProfileStyle>
    <ProfileImg src={user.avatar} alt={user.username} />
  </ProfileStyle>
);

Profile.propTypes = {
  user: PropTypes.shape({
    username: PropTypes.string,
    avatar: PropTypes.string,
    email: PropTypes.string
  }).isRequired
};

export default Profile;
