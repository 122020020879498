import React from "react";
import PropTypes from "prop-types";

import { PrimaryButtonStyle, OutlineButtonStyle } from "./style";

const Button = ({ loading, disabled, variant, ...rest }) => {
  switch (variant) {
    case "primary":
      return (
        <PrimaryButtonStyle
          disabled={loading || disabled}
          variant={variant}
          {...rest}
        />
      );
    case "outline":
      return (
        <OutlineButtonStyle
          disabled={loading || disabled}
          variant={variant}
          {...rest}
        />
      );
    default:
      return (
        <PrimaryButtonStyle
          disabled={loading || disabled}
          variant={variant}
          {...rest}
        />
      );
  }
};

Button.defaultProps = {
  loading: false,
  disabled: false,
  variant: "primary"
};

Button.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  variant: PropTypes.string
};

export default Button;
