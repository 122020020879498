import React from "react";

import AppProviders from "./context/app-providers";
import AppWrapper from "./components/app-wrapper";
import Routes from "./routes";

const App = () => (
  <AppProviders>
    <AppWrapper>
      <Routes />
    </AppWrapper>
  </AppProviders>
);

export default App;
