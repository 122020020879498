import styled from "styled-components";

import { FOR_MEDIA_LARGE_UP, MAX_WIDTH } from "../style";

export const RoutesWrapperStyle = styled.main`
  grid-area: main;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${MAX_WIDTH}px;
  height: 100%;
  padding: 0 1rem;
  @media (min-width: ${FOR_MEDIA_LARGE_UP}px) {
    padding-left: 0rem;
    padding-right: 0rem;
  }
`;
