import React from "react";
import { Link } from "react-router-dom";

import { LogoStyle } from "./style";

const Logo = () => (
  <LogoStyle>
    <Link to="/">Natanada</Link>
  </LogoStyle>
);

export default Logo;
