import styled from "styled-components";

export const ProfileStyle = styled.div`
  grid-area: profile;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: 35px;
  height: 35px;
  overflow: hidden;
  cursor: pointer;
`;

export const ProfileImg = styled.img`
  background-color: aliceblue;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
`;
