import React from "react";
import { useQuery, useMutation, useApolloClient } from "react-apollo-hooks";

import { CURRENT_USER } from "../graphql/queries/user";
import { LOGOUT } from "../graphql/mutations/user";

const AuthContext = React.createContext();

const AuthProvider = props => {
  const apolloClient = useApolloClient();
  const logoutMutation = useMutation(LOGOUT);
  const { data, loading } = useQuery(CURRENT_USER);

  // if no currentUser returned it will be undefined
  const currentUser = React.useMemo(() => {
    return !loading && data && data.currentUser;
  }, [data, loading]);

  // logout
  const logout = React.useCallback(async () => {
    try {
      const res = await logoutMutation();
      if (res.data && res.data.logout) {
        // clear apollo store
        await apolloClient.clearStore();
        // navigate to home page
        window.location.replace("/");
      }
    } catch (error) {
      // console.log("logout Error 👀", error);
    }
  }, [logoutMutation, apolloClient]);

  return (
    <AuthContext.Provider
      value={{ currentUser, logout, loadingUser: loading }}
      {...props}
    />
  );
};

const useAuth = () => {
  const context = React.useContext(AuthContext);
  return context;
};

export { AuthProvider, useAuth };
