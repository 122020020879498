import ApolloClient, { InMemoryCache } from "apollo-boost";

const cache = new InMemoryCache({
  dataIdFromObject: data => {
    /*eslint-disable */
    switch (data.__typename) {
      case "User":
        return data.username;
      default:
        return data.id || data._id;
    }
  } /* eslint-enable */
});

export const client = new ApolloClient({
  uri: "/graphql",
  credentials: "include",
  cache
});
