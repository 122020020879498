import React from "react";
import { Link } from "react-router-dom";

import { useAuth } from "../../context/auth-context";
import { HeaderStyle, HeaderResponsive, ButtonWrapper } from "./style";
import Logo from "../logo";
import Profile from "../profile";
import Button from "../buttons";

const Header = () => {
  const { currentUser } = useAuth();
  return (
    <HeaderStyle>
      <HeaderResponsive>
        <Logo />
        {currentUser ? (
          <Profile user={currentUser} />
        ) : (
          <ButtonWrapper>
            <Button>
              <Link to="/sign-in">Sign In</Link>
            </Button>
          </ButtonWrapper>
        )}
      </HeaderResponsive>
    </HeaderStyle>
  );
};
export default Header;
