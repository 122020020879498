import React from "react";
import PropTypes from "prop-types";

import { useAuth } from "../context/auth-context";

const SwitchComponents = props => {
  const { currentUser, loadingUser } = useAuth();
  const { Component, FallBack, ...rest } = props;
  if (loadingUser) return null;
  return currentUser ? <Component {...rest} /> : <FallBack {...rest} />;
};

const fallbackTo = (Component, FallBack) => {
  return props => (
    <SwitchComponents Component={Component} FallBack={FallBack} {...props} />
  );
};

SwitchComponents.propTypes = {
  Component: PropTypes.elementType.isRequired,
  FallBack: PropTypes.elementType.isRequired
};

export default fallbackTo;
