import { gql } from "apollo-boost";

export const SIGNUP = gql`
  mutation SIGNUP($input: CreateUserInput!) {
    signup(input: $input)
  }
`;

export const SIGNIN = gql`
  mutation SIGNIN($input: SignInInput!) {
    signin(input: $input)
  }
`;

export const LOGOUT = gql`
  mutation LOGOUT {
    logout
  }
`;

export const REQUEST_RESET_PASSWORD = gql`
  mutation REQUEST_RESET_PASSWORD($input: RequestResetPasswordInput!) {
    requestResetPassword(input: $input)
  }
`;

export const RESET_PASSWORD = gql`
  mutation RESET_PASSWORD($input: ResetPasswordInput!) {
    resetPassword(input: $input)
  }
`;
