import { gql } from "apollo-boost";

const CURRENT_USER = gql`
  query CURRENT_USER {
    currentUser {
      username
      email
      avatar
    }
  }
`;

export { CURRENT_USER };
