import styled from "styled-components";

import { MAX_WIDTH, FOR_MEDIA_LARGE_UP } from "../style";

export const FooterStyle = styled.footer`
  grid-area: footer;
  display: flex;
  justify-content: center;
  align-content: center;
  width: 100%;
  height: 100%;
  background-color: white;
  border-top: 1px solid #e8e8e8;
`;

export const FooterResponsive = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  grid-template-areas:
    "logo . ."
    "links links links"
    "social . .";
  grid-gap: 1rem;
  align-items: flex-start;
  justify-items: flex-start;
  width: 100%;
  max-width: ${MAX_WIDTH}px;
  padding: 1.4rem 1rem;
  height: 100px;
  @media (min-width: ${FOR_MEDIA_LARGE_UP}px) {
    padding-left: 0rem;
    padding-right: 0rem;
  }
`;

export const LogoArea = styled.div`
  grid-area: logo;
`;
