import React from "react";
import PropTypes from "prop-types";
import { ApolloProvider } from "react-apollo";
import { ApolloProvider as ApolloHooksProvider } from "react-apollo-hooks";
import { ThemeProvider } from "styled-components";

import { AuthProvider } from "./auth-context";
import { client } from "../apollo-client";
import { theme } from "../theme";

const AppProviders = ({ children }) => (
  <ApolloProvider client={client}>
    <ApolloHooksProvider client={client}>
      <ThemeProvider theme={theme}>
        <AuthProvider>{children}</AuthProvider>
      </ThemeProvider>
    </ApolloHooksProvider>
  </ApolloProvider>
);

AppProviders.propTypes = {
  children: PropTypes.element.isRequired
};

export default AppProviders;
