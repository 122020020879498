import React from "react";

import { FooterStyle, FooterResponsive, LogoArea } from "./style";
import Logo from "../logo";

const Footer = () => (
  <FooterStyle>
    <FooterResponsive>
      <LogoArea>
        <Logo />
      </LogoArea>
    </FooterResponsive>
  </FooterStyle>
);

export default Footer;
